import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, of, shareReplay } from 'rxjs';
import { Logger } from 'src/app/@common/log/logger';
import { AppConfig } from '../../../@common/configuration/app-config.service';
import { Page } from '../../../@common/entities/page.interface';
import { PaginationUtility } from '../../../@common/util/pagination.utility';
import { News } from '../entities/news.interface';

@Injectable({
    providedIn: 'root',
})
export class NewsService {
    private http = inject(HttpClient);
    private logger = inject(Logger);
    private appConfig = inject(AppConfig);

    getAllNews(limit: number, page: number): Observable<Page<News>> {
        return this.http.get<Page<News>>(`${ this.appConfig.apiUrl }/news/latest/?limit=${ limit }&offset=${ PaginationUtility.offset(limit, page) }`)
            .pipe(
                shareReplay(1),
                catchError(e => {
                    this.logger.apiError('Get news failed', e);
                    return of({});
                })
            );
    }

}
